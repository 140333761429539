@font-face {
  font-family: 'AvenirMedium';
  src: url(./assets/fonts/Avenir-Medium.ttf) format('truetype');
  font-display: block;
}

@font-face {
  font-family: 'AvenirBook';
  src: url(./assets/fonts/Avenir-Book.ttf) format('truetype');
  font-display: block;
}

@font-face {
  font-family: 'AvenirHeavy';
  src: url(./assets/fonts/Avenir-Heavy.ttf) format('truetype');
  font-display: block;
}

@font-face {
  font-family: 'RobotoCondensedBold';
  src: url(./assets/fonts/RobotoCondensed-Bold.ttf) format('truetype');
  font-display: block;
}

@keyframes slideIn {
  0% {
    transform: translateX(-20px);
    opacity: 0.2
  }

  100% {
    transform: translateX(0);
    opacity: 1
  }
}

@keyframes rotateAnim {
  100% {
    transform: rotate(360deg)
  }
}

@keyframes moveAside {
  100% {
    gap: 16px;
  }
}

@keyframes toast-enter-left {
  0% {
    transform: translateX(-20px);
    opacity: 0;
  }

  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}

@keyframes toast-exit-left {
  0% {
    transform: translateX(0px);
    opacity: 1;
  }

  100% {
    transform: translateX(-20px);
    opacity: 0;
  }
}

@keyframes toast-enter-right {
  0% {
    transform: translateX(20px);
    opacity: 0;
  }

  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}

@keyframes toast-exit-right {
  0% {
    transform: translateX(0px);
    opacity: 1;
  }

  100% {
    transform: translateX(20px);
    opacity: 0;
  }
}

@keyframes slideInOverlay {
  from {
    transform: translateX(0);
    opacity: 0;
  }

  to {
    transform: translateX(-100%);
    opacity: 1;
  }
}